import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0,3];

export const dictionary = {
		"/app/my-companies": [~5,[],[2]],
		"/app/my-companies/tags": [~6,[],[2]],
		"/app/my-contacts": [~7,[],[2]],
		"/app/my-contacts/tags": [~8,[],[2]],
		"/compose/activity": [9,[3,4]],
		"/compose/activity/[filter]": [10,[3,4]],
		"/compose/drafts": [11,[3]],
		"/compose/templates": [12,[3]],
		"/dashboard": [13],
		"/workflows": [14]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';