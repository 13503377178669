import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

import { dev } from '$app/environment';
import { env } from '$env/dynamic/public';

if (!dev) {
  Sentry.init({
    dsn: env.PUBLIC_RR_APP_SENTRY_DSN,
    environment: env.PUBLIC_SENTRY_ENVIRONMENT,
    release: env.PUBLIC_VERSION,
    ignoreErrors: [
      'No extension id.', // Not user facing and should be fixed but potentially cached

      // The following three errors occur when a new version of the app is deployed while a
      // user is currently in the app - SvelteKit detects it and reloads with the latest version
      // so there shouldn't be any real impact to the user other than a potential extra reload of
      // the page
      'Importing a module script failed.', // Webkit browsers (Safari, etc.)
      'Failed to fetch dynamically imported module', // Chromium browsers (Chrome, etc.)
      'error loading dynamically imported module', // Firefox

      // The following errors can be caused by a variety of things - network issues, browser extensions,
      // rapid navigation between pages while fetches are still in progress, etc.
      // The issues in Sentry are always vague and are unrelated to our own code.
      // (Using regular expressions for exact matching since more specific errors might also contain
      // these phrases)
      /^Load failed$/, // Webkit browsers (Safari, etc.)
      /^Failed to fetch$/, // Chromium browsers (Chrome, etc.)

      // The following errors seem to be caused by uncaught rejections in third-party scripts that we
      // load
      'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
      'Event `Event` (type=unhandledrejection) captured as promise rejection',
    ],
    integrations: [
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['rr-app'],
        behaviour: 'apply-tag-if-contains-third-party-frames',
      }),
    ],
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
